.resources {
  align-items: center;
  background-color: #f3f2ef;
  padding-bottom: 40px;
  padding-top: 5rem;
}

@font-face {
  font-family: "Gotham-Medium";
  src: url("../../fonts/Gotham-Medium.otf");
}

@font-face {
  font-family: "Gotham-Book";
  src: url("../../fonts/Gotham-Book.otf");
}

.heading {
  font-family: "Gotham-Medium" !important;
}

.sidebar {
  width: 250px;
}

.resource__body {
  display: flex;
  flex-direction: row;
  gap: 5rem;
  width: 90%;
  margin-top: 30px;
  margin: 35px auto;
}

.links__body {
  width: 100%;
  /* padding-right: 10rem; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
}

.resources-btn {
  margin-top: 2rem !important;
  color: #57068c !important;
  background-color: transparent !important;
  border-radius: 0 !important;
  border-top: 1px solid #57068c !important;
  border-right: 1px solid #57068c !important;
  border-bottom: 1px solid #57068c !important ;
  border-left: 4px solid #57068c !important;
  transition: 0.3s !important;
  /* padding: 1.6rem 10rem 1.6rem 10rem !important; */
  font-size: large !important;
}

.past-events-btn {
  color: #f5f0f8 !important;
  background-color: #57068c !important;
  border-radius: 0 !important;
  transition: 0.3s !important;
  /* padding: 1.6rem 10rem 1.6rem 10rem !important; */
  font-size: medium !important;
}

.event-header {
  display: flex;
  align-items: center;
  margin: 20px 0px 20px 0px; 
  font-family: "Gotham-Medium" !important;
}

.book-font{
  font-family: "Gotham-Book" !important;
}

.medium-font{
  font-family: "Gotham-Medium" !important;
}

/* The line after the month text */
.line {
  flex-grow: 1; /* This will make the line take up the rest of the space */
  height: 1px;
  background-color: black; 
}

.event-body-column {
  display: flex;
  flex-direction: column;
}
.responsive-image {
  width: 100%;    /* The image will take up 100% of the container's width */
  height: auto;   /* Maintains the aspect ratio by adjusting the height automatically */
  display: block; /* Removes bottom space that might appear below the image */
}

.event-link {
  color: #292929 !important;
  text-decoration: none !important;
}

.event-link:hover {
  text-decoration: underline !important
}


