*{
    margin: 0;
}

.sidebar {
    position: sticky;
    top: 80px;
    flex: 0.2;
    border-radius: 10px;
    text-align: center;
    padding-top: 10px;
    height: fit-content;
}
  
.sidebar__avatar {
    margin-bottom: 10px;
}
  
.sidebar__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid lightgray;
    border-bottom: 0;
    border-radius: 10px;
    padding: 10px;
    background-color: white;
}
  
.sidebar__top > img {
    margin-bottom: -20px;
    height: 60px;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    object-fit: cover;
}
  
.sidebar__top > h4 {
    color: gray;
    font-size: 12px;
}
  
.sidebar__top > h2 {
    font-size: 18px;
}
  


/* @media screen and (max-width: 900px) {
    .sidebar {
        display: none;
    }
} */