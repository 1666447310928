.schools {
  align-items: center;
  background-color: #f3f2ef;
  padding-bottom: 40px;
  padding-top: 5rem;
}

.schools_body {
    display: flex;
    flex-direction: column;
    width: 100%;
}

/* .schools_list {
    display: flex;
    flex-direction: column;
} */
