@font-face {
  font-family: "Gotham-Medium";
  src: url("../../fonts/Gotham-Medium.otf");
}

@font-face {
  font-family: "Gotham-Book";
  src: url("../../fonts/Gotham-Book.otf");
}

.chat {
  padding: 8rem 15rem;
}

.forum {
  padding: 3rem 5rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.title1 {
  margin-bottom: 25px !important;
  font-weight: 500 !important;
  font-family: "Gotham-Medium" !important;
}

.create-post-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.create-post-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 1rem;
}

.post-mentee-details {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 1rem;
}

.mentee-name {
  font-family: "Gotham-Medium" !important;
  font-weight: 100 !important;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* Transparent white background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.comment {
  margin: 1rem 2rem 0rem 7rem;
  border: 1px solid rgb(192, 178, 178);
  padding: 0.8rem;
}

.post-title {
  font-family: "Gotham-Medium" !important;
  font-size: 20px !important;
  margin-bottom: 1rem !important;
}

.post-content {
  font-family: "Gotham-Book" !important;
}

.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}