.container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

.card {
    padding: 16px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
    width: 50vh;
  }
  
  .btn {
    margin-top: 40px;
  }

  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8); /* Transparent white background */
    display: flex;
    align-items: flex-start;
    justify-content: center;
    z-index: 9999;
    /* padding-top: 4rem; */
  }
  