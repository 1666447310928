@font-face {
  font-family: "Gotham-Bold";
  src: url("../../fonts/Gotham-Bold.otf");
}

.nav-items {
  color: #fff;
  font-size: 15px;
  text-transform: uppercase;
  font-family: "Gotham-Bold" !important;
}

.nav-button {
    text-decoration: none;
    padding: 0rem 2rem !important;
    height: 100%;
    border-radius: 0px !important;
}

.nav-button:hover {
    background-color: #410d63 !important;
}

.header-btn {
  background-color: #fff !important;
  color: black !important;
  border-radius: 5px;
}