.home {
  background-color: white;
}
.home__container {
  display: flex;
  align-items: flex-start; /* Align items to the top */
  padding: 10rem 5rem 10rem 8rem;
  flex-direction: row;
  gap: 4rem;
}

@font-face {
  font-family: "Gotham-Medium";
  src: url("../../fonts/Gotham-Medium.otf");
}

@font-face {
  font-family: "Gotham-Book";
  src: url("../../fonts/Gotham-Book.otf");
}

/* .home_overview {
  width: 100%;
} */

.article {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.article > * {
  flex-basis: 100%;
  /* margin: auto; */
}
.article__header {
  margin-top: 0px;
  padding-top: 0px;
}

.heading {
  font-size: 58px;
  margin-bottom: 25px;
  /* letter-spacing: 2px; */
  font-weight: 500;
  font-family: "Gotham-Medium";
}

.empty_space {
  position: relative;
  visibility: hidden;
  top: -6rem;
}

.subheading {
  margin-bottom: 2.5rem;
  color: #686868;
  -webkit-font-smoothing: antialiased;
  font-size: 1.75rem;
  line-height: 1.2;
  font-weight: 500;
  font-family: "Gotham-Medium";
}

.title {
  font-family: "Gotham-Medium";
  font-weight: 500;
}

.content {
  line-height: 25px;
  font-family: "Gotham-Book";
  color: #686868;
  text-align: justify;
  font-size: 17px;
}

.pointer__content {
  line-height: 32px;
  font-family: "Gotham-Book";
  color: #686868;
  font-size: 17px;
}

.image-column {
  display: flex;
  flex-direction: column;
  margin-top: 1.2rem;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.img1 {
  max-width: 80%;
  height: 18rem;
}

.img2{
  max-width: 70%;
  height: 15rem;
}

.article__content {
  justify-content: space-between;
}

.article__content h1 {
  margin-top: 30px;
  font-weight: 400;
}

.article__content p {
  margin-top: 10px;
}

.para_subheading {
  font-weight: 500;
  color: #292929;
  font-size: 1.5rem;
  font-family: "Gotham-Medium";
  line-height: 1.2;
  margin-bottom: 20px;
  margin-top: 30px !important;
}

.NYU_links {
  color: #57068c;
  /* text-decoration: none; */
}

.profile-list li {
  margin-top: 8px;
}

@media screen and (max-width: 1527px) {
  .heading {
    font-size: 50px;
  }

  .home__container {
    padding: 10rem 7rem;
  }

  .img1 {
    max-width: 90%;
    height: 18rem;
  }
}

@media screen and (max-width: 1350px) {
  .heading {
    font-size: 45px;
  }

  .home__container {
    padding: 10rem 4rem;
  }
  .img1 {
    max-width: 100%;
    height: 20rem;
  }
}

@media screen and (max-width: 1264px) {
  .heading {
    font-size: 52px;
  }

  .home__container {
    padding: 10rem 4rem;
  }

  .home_overview {
    display: none;
}
}

/* ============== MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width: 1108px) {
  .heading {
    font-size: 48px;
  }

  .home__container {
    padding: 10rem 3rem;
    flex-direction: row;
  }
}

/* ============== MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 980px) {
  .home__container {
    padding: 8rem 3rem;
  }
  .article {
    flex-direction: column;
    gap: 20px;
  }

  .heading {
    font-size: 55px;
  }

  .subheading {
    font-size: 1.17rem;
    line-height: 1.5;
  }
}

@media screen and (max-width: 550px) {
  .home__container {
    padding: 8rem 2rem;
  }

  .heading {
    font-size: 36px;
  }

  .img1 {
    max-width: 100%;
    height: 15rem;
  }

  .img2 {
    max-width: 100%;
    height: 12rem;
  }
}

