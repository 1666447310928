* {
  margin: 0;
}

@font-face {
  font-family: "Gotham-Bold";
  src: url("../../fonts/Gotham-Bold.otf");
}

@font-face {
    font-family: "Gotham-Book";
    src: url("../../fonts/Gotham-Book.otf");
  }

/* .overview {
  position: sticky;
  top: 100px;
  flex: 0.2;
  border-radius: 10px;
  text-align: center;
  height: fit-content;
  width: 100%;
} */

.overview_top {
  display: flex;
  flex-direction: column;
  border: 1px solid lightgray;
  border-bottom: 0;
  border-radius: 10px;
  padding: 1rem 2rem;
  background-color: white;
}

.on-this-page {
    margin-bottom: 1rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: "Gotham-Bold";
    font-size: 1rem;
    font-weight: 100;
    line-height: 1.5;
    color: #212529;
    text-align: left;
}

.overview_topics_list {
    overflow-y: hidden;
    list-style: none;
    text-align: left;
    padding-left: 0;
}

.overview_topic {
    font-family: "Gotham-Book";
    font-size: 15px;
    border-top: 4px solid #f3f2ef;
    padding-top: 10px;
    padding-bottom: 10px;   
}

.overview_topic > a {
    color: #57068C;
    text-decoration: none;
    transition: 0.3s;
}
