.profile {
  align-items: center;
  background-color: #f3f2ef;
  padding-bottom: 40px;
  padding-top: 5rem;
}

.sidebar {
  width: 250px;
}

.profile__body {
  display: flex;
  flex-direction: row;
  gap: 8rem;
  width: 90%;
  margin-top: 30px;
  margin: 35px auto;
}

.goals__body {
  width: 100%;
  /* margin-right: 10rem; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.list-header {
  display: flex;
  flex-direction: row;
  gap: 30px;
  margin-bottom: 20px !important;
  align-items: flex-end;
}

.goals__topic {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}

.goals-list {
  margin-top: 10px;
}

.goals {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;
}

.list{
  list-style: none;
  padding: 0;
  margin: 0;  
}

.no-goals{
  display: flex;
}

.progress {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-bottom: 40px;
  margin-top: 20px;
  margin-left: 2.5em;
}

.progress-bar {
  height: 20px !important;
  border-radius: 10px;
  background-color: #E0E0E0 !important; 
  transition: width 0.5s ease-in-out;
}

.progress-bar .MuiLinearProgress-bar {
  background-color: #77DD77 !important; /* Color for completed progress */
  transition: width 0.5s ease-in-out;
}

@media screen and (min-width: 1900px) {
  .goals-list{
    margin-right: 50rem;
  }
}

@media screen and (max-width: 1900px) {
  .goals-list{
    margin-right: 20rem;
  }
}

@media screen and (max-width: 1076px) {
  .goals-list{
    margin-right: 10rem;
  }
}

@media screen and (max-width: 500px) {
  .goals-list{
    margin-right: 0rem;
  }
}