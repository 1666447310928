.mentors {
  align-items: center;
  background-color: #f3f2ef;
  padding-bottom: 40px;
  padding-top: 5rem;
}

.mentors__body {
  display: flex;
  flex-direction: row;
  gap: 40px;
  margin-top: 30px;
  margin: 35px 5rem;
}

.mentors-list {
  display: flex;
  flex-direction: column;
  gap: 50px;
  /* width: 100%; */
  flex-grow: 1;
}

.department-list {
  display: flex;
  flex-direction: column;
}

.sidebar {
  width: 250px;
}

.department-title {
  margin-bottom: 20px !important;
  font-weight: 600 !important;
}

.card-btn {
  margin-top: 1rem !important;
  color: #57068c !important;
  background-color: transparent !important;
  border-radius: 0 !important;
  border-top: 1px solid #57068c !important;
  border-right: 1px solid #57068c !important;
  border-bottom: 1px solid #57068c !important ;
  border-left: 4px solid #57068c !important;
  transition: 0.3s !important;
}

.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card-content {
  padding: 35px 25px 25px !important;
}

.mentor-name {
  color: #292929 !important;
  text-decoration: underline;
  margin-bottom: 20px !important;
  font-weight: 500 !important;
  line-height: 1.2 !important;
}

.button-container {
  margin-top: auto;
}

@media screen and (max-width: 1024px) {
  .mentors__body {
    margin: 35px 3rem;
  }
}

