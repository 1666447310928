.my-mentors {
  align-items: center;
  background-color: #f3f2ef;
  padding-bottom: 40px;
  padding-top: 5rem;

}

.my-mentors-list {
  width: 100%;
}

.department-list {
  display: flex;
  flex-direction: column;
}

.my-mentors-body {
  display: flex;
  flex-direction: row;
  gap: 40px;
  width: 90%;
  margin: 35px auto;
}

.card-btn {
  margin-top: 1rem !important;
  color: #57068c !important;
  background-color: transparent !important;
  border-radius: 0 !important;
  border-top: 1px solid #57068c !important;
  border-right: 1px solid #57068c !important;
  border-bottom: 1px solid #57068c !important ;
  border-left: 4px solid #57068c !important;
  transition: 0.3s !important;
}

.no-mentors {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding-right: 20vh;
}

.card-content {
  padding: 35px 25px 25px !important;
}

.mentor-name{
  color: #292929 !important;
  text-decoration: underline;
  margin-bottom: 20px !important;
  font-weight: 500 !important;
    line-height: 1.2 !important;
}

.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.list-header {
  display: flex;
  flex-direction: row;
  gap: 30px;
  margin-bottom: 20px !important;
  align-items: flex-end;
}

.button-container {
  margin-top: auto;
}

@media screen and (max-width: 900px) {
  .sidebar {
      display: none;
  }

  .no-mentors{
    justify-content: center;
    align-items: center;
    padding: 0px;
  }
}