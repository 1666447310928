*{
    margin: 0;
}

.overview_with_sidebar{
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 80px;
    /* flex: 0.2; */
    border-radius: 10px;
    text-align: center;
    height: fit-content;
    /* width: 100%; */
    /* width: -webkit-fill-available; */
}

.overview_with_sidebar_top {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    border-bottom: 0;
    /* padding-top: 10px; */
}



@media screen and (max-width: 900px) {
    .overview_with_sidebar {
        display: none;
    }
}